<template>

  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

  <TopMenu :breadcrumbs="breadcrumbs"/>

	<div class="load-container" :class="{'load' : isLoad}">
    <div class="content" v-if="!isLoad && !isError">
    	
    	<div class="content-header">
    		<div class="content-header__left">
	    		<div class="content-header__ico">
	    			<component :is="module_id" color="#008DD2"></component>
	    		</div>
	    		<div class="content-header__name">{{ module.name }}</div>
    		</div>

    		<div class="content-header__fotofix">
	    		<div class="content-photofix">
	    			<div class="switcher">
	    				<label for="fotofix_p">
	    					<img src="@/assets/img/camera-blue-p.svg"> 
	    					<span>Фотофиксация работ</span>
	    				</label>
	    				<input type="checkbox" id="fotofix_p" v-model="module.workImgRequired" @change="() => { setModuleImgRequired() }">
	    			</div>
	    		</div>

	    		<div class="content-photofix">
	    			<div class="switcher">
	    				<label for="fotofix_o">
	    					<img src="@/assets/img/camera-blue-o.svg"> 
	    					<span>Фотофиксация осмотров</span>
	    				</label>
	    				<input type="checkbox" id="fotofix_o" v-model="module.reviewImgRequired" @change="() => { setModuleImgRequired() }">
	    			</div>
	    		</div>
    		</div>


    	</div>
    	
      <CollapseContainer v-for="(epic) in module.epics" :key="epic.num" class="collapse--noborder" :ref="'epic-' + epic.num">
        <template v-slot:header>
        	<div class="epic-header">
        		<div class="epic-header__left">
    					<span class="collapse-label me-1">{{ epic.num }}</span> 
    					{{ epic.name }}
        		</div>
    				
    				<div class="epic-header__status" v-if="epic.dateActiveEpic !== null"> {{ $moment(epic.dateActiveEpic).format('DD.MM.YYYY') }}</div>
    				<div class="epic-header__status epic-header__status--inactive" v-else></div>

        	</div>
        </template>
        <template v-slot:body>
		      <CollapseContainer v-for="(topic) in epic.topics" :key="topic.num" :class="{'collapse--disabled': !topic.active}" :ref="'topic-' + topic.num">
		        <template v-slot:header>
		        	<div class="switcher">
								<input type="checkbox" v-model="topic.active" :id="topic.num" @change="() => { setTopicActive(topic) }">
							</div>
							<span class="collapse-label me-1 ms-1" :for="topic.num">{{ topic.num }}</span> 
							{{ topic.name }}
		        </template>
		        <template v-slot:body>
		        	<div class="topic">
								<div class="topic__header">
									<div class="topic__hitem"> Тип </div>
									<div class="topic__hitem"> Наименование </div>
									<div class="topic__hitem"> Локация </div>
									<div class="topic__hitem"> Периодичность </div>
									<div class="topic__hitem"> Расписание </div>
									<div class="topic__hitem"> Квалификация </div>
									<div class="topic__hitem"> Сотрудник </div>
									<div class="topic__hitem"> Дней на ипсполн </div>
								</div>
								<div class="topic__body">
									<Topic 
										:topic="topic"
										:employees="employees" 
										:functions="functions"
									/>
								</div>
							</div>
		        </template>
		      </CollapseContainer>
        </template>
      </CollapseContainer>

    	<div class="block">
    		<div class="block__body">
    			<div class="btn-group">
	    			<router-link :to="{name: 'PageOrganizationHouseTechMap', params: {organization_id: organization_id, house_id: house_id} }" class="btn btn--lg btn--outline">
	            <img class="btn--ico btn--ico--left" src="@/assets/img/prew-arrow-blue.svg">
	            <div class="btn--text"> вернуться </div>
	            <div class="btn--loader"></div>
	          </router-link>
	          <a class="btn btn--lg btn--default" @click="activateTemplate($event)" :class="{'disabled': module_id == 'B' && roleId !== 'admin'}">
	            <img class="btn--ico btn--ico--left" src="@/assets/img/check-white.svg">
	            <div class="btn--text"> Активировать шаблоны </div>
	            <div class="btn--loader"></div>
	          </a>
    			</div>
    		</div>
    	</div>
    	
		</div>
		<ErrorContent v-else />
	</div>
</template>

<script setup>

  import TopMenu from '@/components/TopMenu.vue'
  import ErrorContent from '@/components/ErrorContent.vue'
  import Topic from '@/components/house/Topic.vue'

  import MessageDialogue from '@/components/modals/MessageDialogue.vue'
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue'
  import CollapseContainer from '@/components/ux/CollapseContainer.vue'

  import A from '@/components/icons/modules/A.vue';
  import B from '@/components/icons/modules/B.vue';
  import C from '@/components/icons/modules/C.vue';
  import D from '@/components/icons/modules/D.vue';
  import E from '@/components/icons/modules/E.vue';
  import F from '@/components/icons/modules/F.vue';
  import G from '@/components/icons/modules/G.vue';
  import H from '@/components/icons/modules/H.vue';

</script>

<script>
  export default {
    
    name: 'PageOrganizationHouseTechMapModule',

    components: { A, B, C, D, E, F, G, H },

    data: () => ({
      isLoad: true,
      isError: false,

      breadcrumbs: [],
      
      organization: null,
      house: null,
      metaTemplate: null,
      employees: null,
    }),

    computed: {
      organization_id () {
        return this.$route.params.organization_id;
      },
      house_id () {
        return this.$route.params.house_id;
      },
      module_id () {
        return this.$route.params.module_id;
      },
      module () {
      	return this.metaTemplate.modules[this.module_id];
      },
      functions () {
        return this.$store.state.functions;
      },
      roleId () {
      	return this.$store.state.auth.user.roleId;
      }
    },

   	created () {
      Promise.all([
      	this.loadOrganization(), 
      	this.loadHouse(), 
      	this.loadMetaTemplate(), 
      	this.loadEmployees(), 
      	this.$store.dispatch('getFunctions')
      ])
      .then(async () => {
        await this.beforeRender();
        this.isLoad = false;
      })
      .catch((e)=> {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })
    },

    methods: {

    		async beforeRender () {
    			if(this.metaTemplate['modules'] === undefined || !this.module) {
    				await this.$refs.confirmDialogue.show({
		          message: 'Техкарта не сгенерирована',
		          okButton: 'Подтвердить',
		          cancelButton: 'Отменить',
		        });
    				this.$router.push({name: 'PageOrganizationHouseTechMap', params: {organization_id: this.organization_id, house_id: this.house_id}})
    			}
	        this.breadcrumbs = [
	        	{ name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico },
	        	{ name: this.organization.name, link: {name:'PageOrganization', params: {organization_id: this.organization_id}}},
	        	{ name: this.house.address, link: {name:'PageHouseMain', params: {house_id: this.house_id}} },
	        	{ name: 'Техкарта', link: {name: 'PageOrganizationHouseTechMap', params: {organization_id: this.organization_id, house_id: this.house_id}} },
	        	{ name: this.module.name, link: '' },
	      	];
      	},

      	setModuleImgRequired () {
      		let param = {
      			moduleNum: this.module_id,
      			workImgRequired: this.module.workImgRequired,
      			reviewImgRequired: this.module.reviewImgRequired,
      		}
      		this.axios.patch(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/modules-img', param);
      	},

      	setTopicActive (topic) {
      		if(!topic.active) {
      			this.$refs['topic-' + topic.num][0].isOpen = false;
      			for (let activity in topic.activities) {
      				topic.activities[activity].active = false;
      			}
      		}
      	},

      	buildTopicRequest () {
      		const r = [];
      		for (const epic in this.module.epics) {
      			for (const topic in this.module.epics[epic].topics) {
      				r.push({ id: this.module.epics[epic].topics[topic].id, active: this.module.epics[epic].topics[topic].active });
      			}
      		}
      		return r;
      	},

      	buildActivityRequest () {

      		const r = [];

      		for (const epic in this.module.epics) {
      			for (const topic in this.module.epics[epic].topics) {
	      			for (const activity in this.module.epics[epic].topics[topic].activities) {

	      				let a = this.module.epics[epic].topics[topic].activities[activity];

        				a.error = [];

	      				let res = {};

	      				res.id = a.id;

	      				if(a.review !== null) {
		      				res.reviewEmployeeId = a.reviewEmployeeId;
		      				res.reviewTtl = Number(a.reviewTtl);
		      				res.reviewFunctionId = Number(a.reviewFunction.id);
		      				if(a.reviewPeriodical) {
		      					if(a.reviewPeriod[Object.keys(a.reviewPeriod)[0]].length == 0) {
					            a.error.push('reviewPeriod');
					          }
		      					res.reviewPeriod = a.reviewPeriod;
		      				}
	      				}

	      				if(a.work !== null) {
		      				res.workEmployeeId = a.workEmployeeId;
		      				res.workTtl = Number(a.workTtl);
		      				res.workFunctionId = Number(a.workFunction.id);
		      				if(a.workPeriodical) {
					          if(a.workPeriod[Object.keys(a.workPeriod)[0]].length == 0) {
					            a.error.push('workPeriod');
					          }
		      					res.workPeriod = a.workPeriod;
		      				}
	      				}

	      				if(a.control !== null) {
		      				res.controlEmployeeId = a.controlEmployeeId;
		      				res.controlTtl = Number(a.controlTtl);
		      				res.controlFunctionId = Number(a.controlFunction.id);
		      				if(a.controlPeriodical) {
		      					if(a.controlPeriod[Object.keys(a.controlPeriod)[0]].length == 0) {
					            a.error.push('controlPeriod');
					          }
		      					res.controlPeriod = a.controlPeriod;
		      				}
	      				}

	      				if(a.error.length > 0) {
				          this.module.epics[epic].topics[topic].isError = true;
				          this.module.epics[epic].isError = true;
				        }

      					r.push(res);

	      			}
      			}
      		}
      		return r;
      	},

      	buildWorksRequest () {
      		const works = [];
      		for (const epic in this.module.epics) {
      			for (const topic in this.module.epics[epic].topics) {
	      			for (const activity in this.module.epics[epic].topics[topic].activities) {
	      				let a = this.module.epics[epic].topics[topic].activities[activity];
	      				works.push({id: a.id, active: a.active});
	      			}
	      		}
	      	}
	      	return works;
      	},

	      async checkError () {
	        // Первый топик с ошибкой
	        let firstErrorTopic = null;
	        // Смотрим эпики
	        for (let epicNum in this.module.epics) {
	          // Эпик с ошибокй
	          if(this.module.epics[epicNum].isError) {
	            // Убираем ошибку
	            this.module.epics[epicNum].isError = false;
	            // Раскрываем колапс эпика         
	            this.$refs['epic-' + epicNum][0].isOpen = true;
	            // Ждём пока раскроется
	            await new Promise(r => setTimeout(r, 300));
	            // Смотрим топики
	            for (let topicNum in this.module.epics[epicNum].topics) {
	              // Топик с ошибкой
	              if(this.module.epics[epicNum].topics[topicNum].isError) {
	                // Первый топик с ошибкой еще не найден
	                if(firstErrorTopic == null) {
	                  // Ставим первый топик с ошибкой
	                  firstErrorTopic = this.$refs['topic-' + topicNum][0].$refs.header;
	                }
	                // Убираем ошибку
	                this.module.epics[epicNum].topics[topicNum].isError = false;
	                // Раскрываем колапс топика
	                this.$refs['topic-' + topicNum][0].isOpen = true;
	            		// Ждём пока раскроется
	              }
	            }
	          }
	        }
	        
	        await new Promise(r => setTimeout(r, 300));
	        if(firstErrorTopic !== null) {
	          firstErrorTopic.scrollIntoView({
	            behavior: 'smooth',
	            block: "start"
	          });
	          return true;
	        } else {
	          return false;
	        }
	      },


      	async activateTemplate (e) {

      		const btn = e.currentTarget;
      		btn.classList.add('btn--load');



      		try {

    				const topics = this.buildTopicRequest();
    				const works = this.buildWorksRequest();
    				const activities = this.buildActivityRequest();

    				const isError = await this.checkError();

		        if(isError) {
		          await new Promise(r => setTimeout(r, 600));
		          btn.classList.remove('btn--load');
		          return;
		        }


		        const isContinue = await this.$refs.confirmDialogue.show({
		          message: 'Аннулировать старые шаблоны и создать новые?',
		          okButton: 'Да',
		          cancelButton: 'Нет',
		        });

	      		if(!isContinue) { 
			        btn.classList.remove('btn--load');
	      			return; 
	      		}




	      		await this.saveTopic(topics);
	      		await this.saveWorks(works);
	      		await this.saveActivity(activities);

	      		let isBuildTemplate = false;

	      		topics.forEach((topic) => {
	      			if(topic.active) {
	      				isBuildTemplate = true;
	      			}
	      		});

	      		if (isBuildTemplate) {

	      			await this.buildTemplate();

	      			await this.loadMetaTemplate();

	      			const confirm = await this.$refs.confirmDialogue.show({
			          message: 'Техкарта сохранена!',
			          okButton: 'Продолжить',
			          cancelButton: 'Остаться',
			        });
			        
			        if(confirm) {
		      			this.$router.push({name: 'PageOrganizationHouseTemplateModule', params: {organization_id: this.organization_id, house_id: this.house_id, module_id: this.module_id }});
		      		}
	      		
	      		} else {
			      
			        this.$refs.messageDialogue.show({
		            message: 'Техкарта сохранена!',
		            text: 'Шаблон не сгенерирован, активируйте хотя бы одну группу',
		            okButton: 'Подтвердить',
		          });
	      		
	      		}

      		} catch (e) {
      			this.$refs.messageDialogue.show({
	            message: e.message,
	            okButton: 'Подтвердить',
	          });
      		}

      		btn.classList.remove('btn--load');
      	},



      	async saveTopic (topics) {
    			await this.axios.patch(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/metaTemplate/topic', { topics: topics });
      	},
      	async saveWorks (works) {
    			await this.axios.patch(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/metaTemplate/status/work-review', { works: works });
      	},
      	async saveActivity (activities) {
    			await this.axios.patch(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/metaTemplate/activity', { activities: activities });
      	},
      	async buildTemplate () {
    			await this.axios.post(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/template');
      	},

      	async loadMetaTemplate () {
      		const r = await this.axios.get(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/metaTemplate');
	        this.metaTemplate = r.data;
      	},
    		async loadOrganization () {
	        const r = await this.axios.get(this.$store.state.api_endpoint + '/companies/' + this.organization_id);
	        this.organization = r.data;
    		},
    		async loadHouse () {
	        const r = await this.axios.get(this.$store.state.api_endpoint + '/houses/' + this.house_id + '?detailed=true');
          this.house = r.data;
    	  },
    	  async loadEmployees () {
	  	  	const r = await this.axios.get(this.$store.state.api_endpoint + '/employees/?companyId=' + this.organization_id + '&houseId=' + this.house_id + '&active=true&detailed=true');
	        this.employees = r.data.data;
	  	  },

    },
  }
</script>

<style scoped>

	.topic__header {
		display: flex;
		flex-direction: row;
		align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #d9e4eb;
    flex-shrink: 0;
    gap: 10px;
    justify-content: flex-start;
    padding: 10px 20px;
    position: relative;
	}
	.topic__hitem {
		color: var(--cozh-dark-grey);
    font-size: 12px;
    font-weight: 500;
	}

 	.topic__hitem:nth-child(8) {
		min-width: 60px;
		max-width: 60px;
	}
	.topic__hitem:nth-child(7) {
		min-width: 150px;
		max-width: 150px;
	}
	.topic__hitem:nth-child(6) {
		min-width: 150px;
		max-width: 150px;
	}
	.topic__hitem:nth-child(5) {
		min-width: 150px;
		max-width: 150px;
	}
	.topic__hitem:nth-child(4) {
		min-width: 150px;
		max-width: 150px;
	}
	.topic__hitem:nth-child(3) {
		min-width: 150px;
		max-width: 150px;
	}
	.topic__hitem:nth-child(2) {
		width: 100%;
	}
	.topic__hitem:nth-child(1) {
		min-width: 30px;
		max-width: 30px;
	}


		
	.btn-group {
		justify-content: space-between;
	}



	.collapse:not(:last-child) {
		margin-bottom: 10px;
	}
	.collapse.open:not(:last-child) {
    margin-bottom: 10px;
  }
	.collapse-label {
    color: var(--cozh-grey);
    font-size: 14px;
    font-weight: 600;
	}
	.collapse:deep(.collapse .collapse__body) {
		padding: 0px;
	}


	.block__body {
	  padding: 20px 25px;
	}


	.content-header {
		background-color: var(--cozh-white);
		padding: 15px 25px;
		border-radius: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.content-header__name {
		font-size: 24px;
		font-weight: 600;
	}
	.content-header__ico {
		min-height: 50px;
		min-width: 50px;
		background-color: rgba(var(--cozh-blue-code), .08);
		border-radius: 50%;
		margin-right: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.content-header__left {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.content-header__fotofix {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
		margin-left: 40px;
	}

	.content-photofix {
		border-radius: 23px;
		display: flex;
		align-items: center;
		background-color: rgba(var(--cozh-blue-code), .08);
		padding: 6px 15px 6px 18px;
	}

	.content-photofix .switcher {
		align-items: center;
	}

	.content-photofix .switcher label {
		margin-left: 0px;
		margin-right: 15px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
	}
	.content-photofix .switcher label img {
		margin-bottom: 2px;
	}
	.content-photofix .switcher label span {
	    white-space: nowrap;
	}

	.epic-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		gap: 20px;
	}
	.epic-header__status {
		font-weight: 400;
		font-size: 13px;
		line-height: 1.5;
		color: rgba(var(--cozh-black-code), .5);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 6px;
	}
	.epic-header__status::before {
		content: '';
		height: 18px;
		width: 18px;
		border-radius: 50%;
		background-color: var(--cozh-blue);
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nOCcgaGVpZ2h0PSc2JyB2aWV3Qm94PScwIDAgOCA2JyBmaWxsPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00xIDNMMyA1TDcgMScgc3Ryb2tlPSd3aGl0ZScgc3Ryb2tlLXdpZHRoPScxLjUnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcvPjwvc3ZnPg==");
		background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
	}
	.epic-header__status--inactive::before {
		background-color: var(--cozh-light-grey);
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nOCcgaGVpZ2h0PSc4JyB2aWV3Qm94PScwIDAgOCA4JyBmaWxsPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00xIDdMNyAxTTcgN0wxIDEnIHN0cm9rZT0nd2hpdGUnIHN0cm9rZS13aWR0aD0nMS41JyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnLz48L3N2Zz4K");
	}

</style>
